$background: #dfe6f9;
$default-color:#031b4e;
$color:#e5ebf3;

/*Color variables*/

$primary:#0068ff;
$secondary:#7987a1;
$pink:#f10075;
$teal:#00cccc;
$purple:#673ab7;
$success:#51bb25 ;
$warning:#f0ad4e;
$danger:#e7505a;
$info:#17a2b8;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#8c0aea;
$white:#ffffff;
$black:#000000;

/*gray variables*/
$gray-100:#f4f6fd;
$gray-200:#eaeefb;
$gray-300:#e1e8fd;
$gray-400:#cad3ef;
$gray-500:#aeb9da;
$gray-600:#919ec3;
$gray-700:#7584ad;
$gray-800:#4a5779;
$gray-900:#2d3856;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow: 12px 7px 13px -3px #b4bfd8;


/* dark theme */

$card-color:#252b3c;

$darktheme-primary:#4f94fb;


/*colors*/
$primary1:#5b84f7;
$primary2:#7659ff;
$primary3:#04a17a;
$primary4:#b65cff;
$primary5:#f2814e;


	

